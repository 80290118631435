// Edit 404-page.template.js (404.js is generated).

import React from 'react';
import { BreakpointProvider } from '../providers/breakpoint-provider';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import WidgetList from '../components/widget-list';

/**
 * 404 Error Page Template
 * Template file used to build the 404 page.
 * @returns Layout of the 404 page.
 */
const ErrorPageTemplate = ({ data, location }) => {
  const page = data?.contentfulCommonPage;
  const globalSettings = data?.contentfulGlobalSettings;

  const extra = {
    forms: globalSettings,
  };

  return (
    <BreakpointProvider>
      <Layout location={location} globalSettings={globalSettings} seoData={page}>
        <WidgetList location={location} widgets={page.mainContent} extra={extra} />
      </Layout>
    </BreakpointProvider>
  );
};

// This query is injected. To edit it, go to generate-404-page.js.
export const pageQuery = graphql`
  query GetErrorPage {
    contentfulCommonPage(setAs404Page: {eq: true}) {
      slug
      pageName
      mainContent {
        ...ArticleCarouselQuery
        ...ArticleGalleryWithSidebarQuery
        ...BiographyGridQuery
        ...ContactFormQuery
        ...GraphicalCalloutGridQuery
        ...GraphicalCalloutListQuery
        ...HeroQuery
        ...IconGridQuery
        ...ImageQuery
        ...JobListQuery
        ...LinksCalloutQuery
        ...LogoGridQuery
        ...MetricGridQuery
        ...NewsGalleryQuery
        ...NewsletterCalloutQuery
        ...RelatedNewsQuery
        ...RichTextComponentQuery
        ...SearchResultsQuery
        ...TestimonialCarouselQuery
        ...TextGridQuery
        ...VideoQuery
      }
    }
    contentfulGlobalSettings {
      ...GlobalSettingsFragment
    }
  }
`;

export default ErrorPageTemplate;
